import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd5d4e6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "single-page-content" }
const _hoisted_2 = {
  key: 0,
  class: "single-page-content__title"
}
const _hoisted_3 = {
  key: 1,
  class: "single-page-content__text"
}
const _hoisted_4 = { class: "page-title-20 distinct--text text-center font-weight-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_progress_steps = _resolveComponent("tm-progress-steps")!
  const _component_white_block = _resolveComponent("white-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.text || _ctx.hasTextSlot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "text", {}, () => [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.text), 1)
          ], true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.steps)
      ? (_openBlock(), _createBlock(_component_tm_progress_steps, {
          key: 2,
          "current-step-index": _ctx.currentStepIndex,
          steps: _ctx.steps,
          class: "mb-10"
        }, null, 8, ["current-step-index", "steps"]))
      : _createCommentVNode("", true),
    (_ctx.hasWhiteBlockSlot)
      ? (_openBlock(), _createBlock(_component_white_block, {
          key: 3,
          class: "single-page-content__white-block"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "white-block", {}, undefined, true)
          ]),
          _: 3
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}
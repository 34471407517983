
import type { PropType } from 'vue'
import {
  defineComponent,
  toRefs,
  computed
} from 'vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import type { StepperStep } from '@/definitions/shared/types'

export default defineComponent({
  props: {
    currentStepIndex: {
      type: Number,
      required: true,
    },
    steps: {
      type: Array as PropType<StepperStep[]>,
      default: () => [],
    },
  },
  setup(props) {
    const {
      steps,
    } = toRefs(props)

    const styleContainer = computed(() => ({
      '--steps-count': steps.value.length,
    }))

    const { isSmMax } = useBreakpoints()

    return {
      styleContainer,
      isSmMax,
    }
  },
})


import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmProgressSteps from '@/components/shared/progressSteps/TmProgressSteps.vue'
import type { StepperStep } from '@/definitions/shared/types'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'

export default defineComponent({
  components: {
    TmProgressSteps,
    WhiteBlock,
  },
  props: {
    title: {
      type: String,
    },
    currentStepIndex: {
      type: Number,
    },
    text: {
      type: String,
    },
    steps: {
      type: Array as PropType<StepperStep[]>,
    },
  },
  setup(props, context) {
    const hasTextSlot = !!context.slots.text
    const hasWhiteBlockSlot = !!context.slots['white-block']
    return {
      hasTextSlot,
      hasWhiteBlockSlot,
    }
  },
})

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31563111"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-progress-step__body" }
const _hoisted_2 = { class: "tm-progress-step__counter" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "tm-progress-step__text ls-reduce-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "tm-progress-steps",
    style: _normalizeStyle(_ctx.styleContainer)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: step.name,
        class: _normalizeClass([{
        'tm-progress-step--active': index < _ctx.currentStepIndex,
        'tm-progress-step--current': index === _ctx.currentStepIndex
      }, "tm-progress-step"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (index < _ctx.currentStepIndex)
              ? (_openBlock(), _createBlock(_component_tm_icon, {
                  key: 0,
                  name: "check",
                  size: "xLarge"
                }))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(index + 1), 1))
          ]),
          (!_ctx.isSmMax || index === _ctx.currentStepIndex)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(step.name), 1))
            : _createCommentVNode("", true)
        ])
      ], 2))
    }), 128))
  ], 4))
}